<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card>
        <div class="custom-search d-flex justify-content-between">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search requests"
              type="text"
            />
            <b-button
              v-b-modal.addRequestModal
              variant="primary"
            >
              <feather-icon icon="PlusIcon" />
              Add Request
            </b-button>
            <b-form-checkbox
              v-model="hideClosed"
              class="d-flex align-items-center mt-1 justify-content-start"
              name="hideClosedRequests"
              aria-label="Hide Closed Requests"
            >
              Hide Closed Requests
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Show requests raised by"
            label-for="btn-radios-1"
            label-size="sm"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="requester"
              :options="[
                { text: 'Startup & Incubator', value: null },
                { text: 'Incubator', value: 'Incubator' },
                {text:'Startup',value:'Startup'},
              ]"
              button-variant="light"
              buttons
              size="sm"
            />
          </b-form-group>

        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="filteredRows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'title'"
              class="d-flex justify-content-between align-items-center"
            >
              {{ props.row.title }}
              <b-button
                variant="link"
                size="sm"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('requestDetailsModal')"
              >View</b-button>
            </span>
            <span v-else-if="props.column.field === 'priority'">
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field])"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'requester' && props.row.raisedbyincubator"
            >
              {{ selectedOrg.title }}
            </span>
            <span
              v-else-if="props.column.field === 'requester' && !props.row.raisedbyincubator"
            >
              {{ props.row.usersAssociationtableByRequester.users_customuser.full_name }}
            </span>
            <span
              v-else-if="props.column.field === 'assigned_to' && props.row.usersAssociationtableByAssignee"
            >
              {{ props.row.usersAssociationtableByAssignee.users_customuser.full_name }}
            </span>
            <span
              v-else-if="props.column.field === 'assigned_to' && !props.row.usersAssociationtableByAssignee"
            >
              {{ selectedOrg.title }}
            </span>
            <span
              v-else-if="props.column.field === 'assignee_status'"
              class="d-flex justify-content-between align-items-center"
            >
              <b-badge
                class="text-capitalize"
                :variant="statusVariant(`${props.formattedRow[props.column.field]}`.toLowerCase())"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
              <b-dropdown
                v-if="!props.row.raisedbystartup"
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
                aria-label="Update assignee status"
              >
                <template #button-content>
                  <span class="align-middle text-nowrap">Update Status <feather-icon
                    class="align-middle ml-25"
                    icon="ChevronDownIcon"
                  /></span>
                </template>
                <b-dropdown-item
                  v-for="option in statusOptions"
                  :key="option.status"
                  :disabled="props.row.assignee_status === option.status"
                  :variant="option.variant"
                  @click="updateRequestAssigneeStatus(props.row.requesttable_id, option.status)"
                >
                  <feather-icon
                    class="mr-50"
                    :icon="option.icon"
                  />
                  <span>{{ option.status.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <span
              v-else-if="props.column.field === 'status'"
              class="d-flex justify-content-between align-items-center"
            >
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field].toLowerCase())"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
              <b-dropdown
                v-if="props.row.raisedbystartup"
                aria-label="Update request status"
                no-caret
                size="sm"
                toggle-class="text-decoration-none"
                variant="link"
              >
                <template #button-content>
                  <span class="align-middle text-nowrap">Update Status <feather-icon
                    class="align-middle ml-25"
                    icon="ChevronDownIcon"
                  /></span>
                </template>
                <b-dropdown-item
                  v-for="option in statusOptions"
                  :key="option.status"
                  :disabled="props.row.status === option.status"
                  :variant="option.variant"
                  @click="updateRequestStatus(props.row.requesttable_id, option.status)"
                >
                  <feather-icon
                    class="mr-50"
                    :icon="option.icon"
                  />
                  <span>{{ option.status.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-modal
        id="addRequestModal"
        :ok-disabled="!(request.title && request.description && request.priority && request.incubator)"
        no-close-on-backdrop
        size="lg"
        title="Add Request"
        ok-title="Add"
        @ok="addRequest"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Select Incubator"
              label-for="Select Incubator"
            >
              <v-select
                v-model="request.incubator"
                class="text-capitalize"
                :options="incubators"
                :get-option-label="option => option.title"
                placeholder="Choose Incubator"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Request Title"
              label-for="Request Title"
            >
              <b-form-input
                id="requestTitle"
                v-model="request.title"
                placeholder="Request Title"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-textarea
                id="requestTitle"
                v-model="request.description"
                placeholder="Description"
                type="text"
                rows="2"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Priority level"
              label-for="Priority level"
            >
              <v-select
                v-model="request.priority"
                class="text-capitalize"
                :options="['low','medium','high','urgent']"
                placeholder="Choose Priority level"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
        id="requestDetailsModal"
        hide-footer
        title="Request Details"
      >
        <div>
          <p>
            {{ requests[selectedRow].description }}
          </p>
          <hr>
          <div
            v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
          >
            <span
              class="font-medium-1"
            >Comments</span>
            <div
              v-for="(comment, index) in requests[selectedRow].comments"
              :key="index"
              class="border-top mt-1"
            >
              <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                {{ comment.created_by }}
                <span><feather-icon icon="CalendarIcon" />
                  {{ comment.created_at }}</span>
              </p>
              <p class="pl-50">
                {{ comment.message }}
              </p>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapGetters } from 'vuex'
import { formatDate } from '@core/utils/utils'

export default {
  components: {
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BCard,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormRadioGroup,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      hideClosed: true,
      pageLength: 25,
      incubators: [],
      statusOptions: [
        { status: 'in-process', variant: 'primary', icon: 'ClockIcon' },
        { status: 'resolved', variant: 'success', icon: 'CheckIcon' },
        { status: 'closed', variant: 'danger', icon: 'XIcon' },
      ],
      columns: [
        {
          label: 'Request Title',
          field: 'title',
        },
        {
          label: 'Priority',
          field: 'priority',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Requester',
          field: 'requester',
        },
        {
          id: 'assigned_to',
          label: 'Assignee',
          field: 'assigned_to',
        },
        {
          label: 'Assignee Status',
          field: 'assignee_status',
          tdClass: 'text-capitalize',
        },
        {
          label: 'Created on',
          field(row) {
            return formatDate(row.created_at, 'MMM DD, YYYY')
          },
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-capitalize',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter by Status',
            filterDropdownItems: ['assigned', 'unassigned', 'in-process', 'resolved', 'closed'],
          },
        },
      ],
      requester: null,
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
        incubator: null,
      },
      assignee: null,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
      userDetails: 'user/getUserDetails',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let { requests } = this
      if (this.requester) {
        requests = requests.filter(r => {
          if (this.requester === 'Incubator') return r.raisedbyincubator
          if (this.requester === 'Startup') return r.raisedbystartup
          return true
        })
      }
      if (this.hideClosed) {
        requests = requests.filter(r => r.status !== 'closed' && r.assignee_status !== 'closed')
      }
      return requests
    },
  },
  methods: {
    addRequest(modalevent) {
      modalevent.preventDefault()
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation addRequest {
            insert_requesttable_one(object: {
              title: "${this.request.title}",
              description: "${this.request.description}",
              priority: "${this.request.priority}",
              incubator_id: ${this.request.incubator.id},
              requester_id: ${this.request.incubator.usersAssociationtablesByAssociatedOrg[0].id},
              is_request: ${true},
              is_startup: ${true},
              status: ${'assigned'},
              assignee_status: ${'unchecked'},
              raisedbystartup: ${true}
            }) {
              requesttable_id
            }
          },
        `,
      })
        .then(() => {
          this.showAlert('Request added successfully', 'success')
          this.request = {
            title: '',
            description: '',
            priority: '',
          }
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
          this.$bvModal.hide('addRequestModal')
        })
        .catch(() => {
          this.showAlert('Error adding request', 'danger')
          this.mutationLoading = false
        })
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.showAlert('Request status updated successfully', 'success')
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.showAlert('Error updating request status', 'danger')
          this.mutationLoading = false
        })
    },
    updateRequestAssigneeStatus(requestId, status) {
      if (!requestId || !status) {
        this.showAlert('Invalid request ID or status', 'danger')
        this.mutationLoading = false
        return
      }

      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
      mutation updateRequestAssigneeStatus($requestId: Int!, $status: String!) {
        update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_status: $status}) {
          requesttable_id
        }
      }
    `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.showAlert('Request assignee status updated successfully', 'success')
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.showAlert('Error updating request assignee status', 'danger')
          this.mutationLoading = false
        })
    },
  },
  apollo: {
    requests: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`query requests {
          requesttable(where: {_or: [{usersAssociationtableByAssignee: {organization_id: {_eq: ${this.selectedOrg.orgId}}}}, {usersAssociationtableByRequester: {organization_id: {_eq: ${this.selectedOrg.orgId}}}}], users_organizationtable: {domain: {_regex: "${subdomain}"}}}, order_by: {requesttable_id: desc}) {
            incubator_id
            is_request
            is_startup
            requesttable_id
            priority
            raisedbystartup
            raisedbyincubator
            requester_id
            created_at
            assignee_status
            status
            title
            description
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      update: data => data.requesttable,
    },
    incubators: {
      query() {
        return gql`
          query incubators($organizationId: Int, $userId: Int, $role: [String!], $subdomain: String!) {
            users_organizationtable(
              where: {
                usersAssociationtablesByAssociatedOrg: {
                  _and: {
                    organization_id: {_eq: $organizationId},
                    user_id: {_eq: $userId},
                    role: {_in: $role}
                  }
                },
                domain: {_regex: $subdomain}
              }
            ) {
              id
              title
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: $organizationId}}) {
                id
              }
            }
          }
        `
      },
      variables() {
        return {
          organizationId: this.selectedOrg?.orgId ? this.selectedOrg?.orgId : null,
          userId: this.userDetails.id,
          role: this.selectedOrg ? ['pre-incubatee', 'incubatee', 'graduate'] : ['student'],
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update: data => data.users_organizationtable,
    },

  },
}
</script>

<style>
.selected-top-tab {
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.deselected-top-tab {
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>
